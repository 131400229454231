<template>
    <div class="courceDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="courceForm" ref="courceDialogForm" :rules="courceRules">
                <el-form-item label="课程名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="courceForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="课程号"  prop="courseNo" :label-width="formLabelWidth">
                    <el-input v-model="courceForm.courseNo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="学科"  prop="subject" :label-width="formLabelWidth">
                    <el-input v-model="courceForm.subject" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="课程性质"  prop="nature" :label-width="formLabelWidth">
                    <el-select v-model="courceForm.nature" filterable placeholder="请选择">
                        <el-option key="" label="无" value=""></el-option>
                        <el-option v-for="item in natureList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'courceDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                professionList: [],
                natureList: ['公共基础课', '专业基础课', '专业课', '专业选修课', '公共选修课', '其他'],
                courceForm:{ id: null, licenceId: null,  courseNo: null, name: null, nature: null,
                    schoolId: null, subject: null, remark: null,  },
                courceRules: {
                    name: [{ required: true, message: '请输入课程名称', trigger: 'blur' }],
                    courseNo: [{ required: true, message: '请输入课程编号', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
	    },
        props: {
            cource: {
                type: Object
            }
        },
        watch: {
            cource: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.courceForm = { id: n.id, licenceId: n.licence_id, courseNo: n.course_no, name: n.name, nature: n.nature,
                            schoolId: n.school_id, subject: n.subject, remark: n.remark };
                        if(n.id == null) this.myTitle = '新建课程'; else this.myTitle = '编辑课程';
                    }else{
                        this.courceForm = { id: null, licenceId: null,  courseNo: null, name: null, nature: null,
                            schoolId: null, subject: null, remark: null,};
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){
                console.log(this.courceForm);
                let that = this;
                this.$refs.courceDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.courceForm.licenceId = this.userInfo.licenceId;
                        this.courceForm.schoolId = this.userInfo.schoolId;
                        let param = { controllerName: 'sclCource', methodName: '/saveOrUpdate', param: this.courceForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('cource', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该教程已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .courceDialog{

    }


</style>


